import React, { Children, useMemo, useState } from 'react';
import { Formik } from "formik";
import { FormAvatar } from "../../../../base/components/Dropzone/Dropzone";
import Title from "../../Biomarkers/Details/Title";
import FormikInput from "../../../../base/components/FormikInput";
import {
  MAX_BIOMARKERS_NAME_LENGTH, MAX_RECOMMENDATION_LENGTH,
  MAX_RULE_NAME_LENGTH,
  MAX_TAG_NAME_LENGTH,
} from "../../Biomarkers/CreateEdit/constants";
import { validationSchema } from "./formValidation";
import FormikReactSelect from "../../../../base/components/FormikReactSelect";
import {
  categoryOptions,
  CONTRADICTIONS,
  DOCTOR_CATEGORY_ID, HEALF_ORG_ID,
  HIERARCHY_OPTIONS,
  IDEAL_FREQUENCY,
  IDEAL_SKIN_TYPE,
  IDEAL_TIME_OF_DAY, selectAllRecommendations,
  SKIN_RECOMMENDATIONS_IDS,
  SUPPLEMENT_CATEGORY_ID,
  TEST_CATEGORY_ID,
  typeOptions
} from "./constants";
import RichTextEditor from "../../../../base/components/RichTextEditor";
import PlusButtonWithTooltip from "../../../../base/components/PlusButtonWithTooltip";
import Icon from "../../../../base/components/Icon";
import joinClassNames from "../../../../base/helpers/joinClassNames";
import ImpactPopup from "./ImpactPopup";
import Button from "../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";
import BiomarkersImpacts, { CartPreview, MultiselectDropdown } from "./components";
import ConfirmPopup from "../../../../base/components/ConfirmPopup";
import { useNavigate } from "react-router-dom";
import { RECOMMENDATIONS_LINKS } from "../config";
import { isObjectEmpty } from "../../../../base/helpers/object";
import Checkbox from "../../../../base/components/Checkbox";
import get from "lodash.get";
import PreventClosePage from "../../../../base/components/PreventClose";
import isDeepEqual from "lodash.isequal";
import FilterDropdown from "../../Biomarkers/CreateEdit/components/FilterDropdown";
import TypeAhead from "./TypeAhead";
import { replaceUrlParams } from "../../../../base/helpers/url";
import RadioButton from "../../../../base/components/RadioButton";
import ParentRecommendationTypeAhead from "./ParentRecommendationTypeAhead";
import ChildrenRecommendationsDropdown from "./ChildrenRecommendationsDropdown";
import BenefitDropdown from "./BenefitDropdown";

export default function RecommendationsForms({
                                               initialValues = { customTagName: "" },
                                               isEdit,
                                               onSubmit,
                                               isSubmitting,
                                             }) {
  const [showProductLink, updateShowProductLink] = useState(false);
  const [showImpactPopup, updateShowImpactPopup] = useState(false);
  const [deleteOption, updateDeleteOption] = useState("");
  const [editOption, updateEditOption] = useState("");
  const [isOpenCancelPopup, updateIsOpenCancelPopup] = useState(false);

  const navigate = useNavigate()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur
      onSubmit={onSubmit}
    >
      {({ errors, handleSubmit, values, setFieldValue }) => {
        const {
          impacts = [],
          category = {},
          customTagName = "",
          type = {},
          productLink,
          idealTimeOfDay = {},
          idealFrequency = {},
          idealSkinTypes = [],
          notMeantForSkinTypes = [],
          contradictions = []
        } = values;

        const canUseProductLink = [TEST_CATEGORY_ID, SUPPLEMENT_CATEGORY_ID, ...SKIN_RECOMMENDATIONS_IDS].includes(category.id);
        const categoriesWithSKUField = [SUPPLEMENT_CATEGORY_ID, TEST_CATEGORY_ID, ...SKIN_RECOMMENDATIONS_IDS];
        const isSkinRecommendation = SKIN_RECOMMENDATIONS_IDS.includes(category.id);
        const isSKUFieldAvailable = categoriesWithSKUField.includes(category.id);

        if(!canUseProductLink && productLink) {
          setFieldValue("productLink", undefined)
        }

        if(!isSkinRecommendation && notMeantForSkinTypes.length) {
          setFieldValue("notMeantForSkinTypes", undefined)
        }

        const getContradictionsLabel = () => {
          if(!contradictions.length) return "";
          if(contradictions.length === CONTRADICTIONS.length) {
            return "All"
          }
          return contradictions.length > 1 ? `${contradictions.length} selected` : contradictions[0].text;
        }

        const isAllSkinTypesChosen = notMeantForSkinTypes.length + idealSkinTypes.length === 5

        return (
          <form className={joinClassNames("form-horizontal p-2", isSubmitting && "pointer-events-none")}
                onSubmit={handleSubmit}>

            <PreventClosePage
              shouldShowCloseAlert={!isDeepEqual(initialValues, values)}
            />

            {showImpactPopup &&
              <ImpactPopup
                isOpen={showImpactPopup}
                onClose={() => updateShowImpactPopup(false)}
                submitImpact={(data) => {
                  setFieldValue("impacts", [...impacts, data])
                }}
                impacts={impacts}
                isDoctorOptionChosen={category.id === DOCTOR_CATEGORY_ID}
                isSkinRecommendation={isSkinRecommendation}
              />
            }

            {isOpenCancelPopup &&
              <ConfirmPopup
                isOpen={isOpenCancelPopup}
                updateIsOpen={updateIsOpenCancelPopup}
                onSubmit={() => {
                  navigate(isEdit ? replaceUrlParams(RECOMMENDATIONS_LINKS.DETAILS, { id: initialValues.id }) : RECOMMENDATIONS_LINKS.LIST)
                }}
                title="Leave"
                description="Are you sure you want to leave the page without saving?"
                submitBtnText="Leave"
                className="w-100"
              />
            }

            {deleteOption &&
              <ConfirmPopup
                isOpen={!!deleteOption}
                updateIsOpen={updateDeleteOption}
                onSubmit={() => {
                  setFieldValue("impacts", impacts.filter(({ biomarker: { id } }) => id !== deleteOption.biomarker.id))
                }}
                title="Delete"
                description="Are you sure you want to delete the chosen impact on biomarker?"
                submitBtnText="Delete"
              />
            }

            {editOption &&
              <ImpactPopup
                isOpen={!!editOption}
                onClose={() => updateEditOption(undefined)}
                submitImpact={(data) => {
                  const indexToEdit = impacts.findIndex(({ biomarker: { id } }) => id === editOption.biomarker.id);
                  const newImpacts = impacts;
                  newImpacts[indexToEdit] = data;
                  setFieldValue("impacts", newImpacts);
                }}
                impacts={impacts}
                isDoctorOptionChosen={category.id === DOCTOR_CATEGORY_ID}
                isEdit
                editOption={editOption}
                isSkinRecommendation={isSkinRecommendation}
              />
            }

            <section className="w-50">
              <Title
                title="General information"
                className="mb-4"
              />
              <FormAvatar/>

              <FormikInput
                placeholder="Enter recommendation title (required)"
                name="title"
                maxLength={MAX_RULE_NAME_LENGTH}
                label="Title"
                containerClassName="mt-3"
              />

              <FormikReactSelect
                setFieldValue={(name, item) => {
                  if (isSkinRecommendation && !SKIN_RECOMMENDATIONS_IDS.includes(item.id)) {
                    setFieldValue("idealTimeOfDay", undefined)
                    setFieldValue("idealSkinTypes", undefined)
                    setFieldValue("notMeantForSkinTypes", undefined)
                    setFieldValue("contradictions", undefined)
                  }
                  setFieldValue(name, item);
                }}
                options={categoryOptions}
                name="category"
                className="custom-select"
                placeholder="Select category (required)"
                value={categoryOptions.find(({ id }) => id === category.id)}
                label="Category"
                withError
                containerClassName="mt-3"
                valuePipe={(item) => item}
              />

              {canUseProductLink && (!showProductLink && !(isEdit && !!productLink)) &&
                <PlusButtonWithTooltip
                  id="addProductLink"
                  onClick={() => updateShowProductLink(true)}
                  buttonText="Add product link"
                  buttonClassName="ps-0"
                  showTooltip={false}
                />
              }

              {isSKUFieldAvailable ?
                <FormikInput
                  placeholder="Product Variant ID (SKU)"
                  name="SKU"
                  max={20}
                  min={0}

                  label="Product Variant ID (SKU)"
                  containerClassName="mt-3"
                /> : null}

              {isSKUFieldAvailable ?
                <section className="mt-3">
                  <label>Hierarchy type</label>
                  <section className="d-flex align-items-center">
                    <RadioButton
                      checked={values.hierarchyType === HIERARCHY_OPTIONS.PARENT}
                      onChange={() => setFieldValue("hierarchyType", HIERARCHY_OPTIONS.PARENT)}
                      className="mt-0"
                      label="Parent"
                    />

                    <RadioButton
                      checked={values.hierarchyType === HIERARCHY_OPTIONS.CHILD}
                      onChange={() => setFieldValue("hierarchyType", HIERARCHY_OPTIONS.CHILD)}
                      className="mt-0"
                      containerClassName="ms-4"
                      label="Child"
                    />
                  </section>
                </section> : null
              }

              <section className="mt-3">
                {values.hierarchyType === HIERARCHY_OPTIONS.PARENT ?
                  <ChildrenRecommendationsDropdown
                    label={"Children Recommendations"}
                    value={values.recommendationChildren?.entities}
                    onChange={(data) => {
                      setFieldValue("recommendationChildrenIds", data);
                    }}
                  /> : null}
                {values.hierarchyType === HIERARCHY_OPTIONS.CHILD ? <ParentRecommendationTypeAhead
                  label="Parent Recommendation"
                  containerClassName="mt-3"
                  onChange={(data) => {
                    setFieldValue("recommendationParentId", data.id);
                  }}
                  onBlur={() => {
                    setFieldValue("recommendationParentId", null);
                  }}
                  value={values.recommendationParent}
                  maxLength={MAX_RECOMMENDATION_LENGTH}
                  placeholder="Enter parent recommendation"
                /> : null}
              </section>

              {values.hierarchyType === HIERARCHY_OPTIONS.PARENT && values.organisationId === HEALF_ORG_ID ?
                  <section className="mt-3">
                    <BenefitDropdown
                        label="Benefit"
                        value={values.benefits}
                        onChange={(data) => {
                          setFieldValue("benefitsIds", data);
                        }}
                        error={errors.benefitsIds}
                    />
                  </section>
                  : null}


              {(showProductLink || (isEdit && !!productLink)) && canUseProductLink &&
                <>
                  <section className="d-flex align-items-center">
                    <FormikInput
                      placeholder="Enter product link"
                      name="productLink"
                      label="Product link"
                      maxLength={MAX_BIOMARKERS_NAME_LENGTH}
                      containerClassName={joinClassNames("mt-3 w-100 ", values.productLink && "me-3")}
                    />
                    {values.productLink &&
                      <Icon
                        className="align-self-end cursor-pointer mb-2"
                        icon="trashIcon"
                        onClick={() => {
                          updateShowProductLink(false)
                          setFieldValue("productLink", undefined)
                          setFieldValue("category", undefined)
                        }}/>
                    }
                  </section>

                  <Checkbox
                    text="Allow add to cart"
                    value={values.isAddToCartAllowed}
                    onChange={() => setFieldValue("isAddToCartAllowed", !values.isAddToCartAllowed)}
                    id="product"
                    className="mt-3"
                  />

                  {values.productLink &&
                    <CartPreview
                      title={values.title}
                      image={get(values, "file[0].preview")}
                      className=""
                      showButton={values.isAddToCartAllowed}
                    />
                  }
                </>
              }

              <TypeAhead
                label="Tag"
                containerClassName="mt-3"
                onChange={(data) => {
                  setFieldValue("customTagName", data)
                }}
                value={customTagName}
                maxLength={MAX_TAG_NAME_LENGTH}
                placeholder="Enter tag"
              />

              <FormikReactSelect
                setFieldValue={setFieldValue}
                options={typeOptions}
                name="type"
                className="custom-select"
                placeholder="Select type"
                value={typeOptions.find(({ id }) => id === type?.id)}
                label="Type"
                withError
                containerClassName=""
                isClearable
                valuePipe={(item) => item}
                additionalComponents={{
                  ClearIndicator: ({ clearValue, innerProps }) => {
                    return <Icon {...innerProps} icon="trashIcon" className="me-2 select-delete-icon"
                                 onClick={clearValue}/>
                  }
                }}
              />

              {isSkinRecommendation &&
                <>
                  <FormikReactSelect
                    setFieldValue={setFieldValue}
                    options={IDEAL_FREQUENCY}
                    name="idealFrequency"
                    className="custom-select"
                    placeholder="Select ideal frequency"
                    value={IDEAL_FREQUENCY.find(({ id }) => id === idealFrequency?.id)}
                    label="Ideal frequency"
                    withError
                    containerClassName="mt-3"
                    valuePipe={(item) => item}
                  />

                  <FormikReactSelect
                    setFieldValue={setFieldValue}
                    options={IDEAL_TIME_OF_DAY}
                    name="idealTimeOfDay"
                    className="custom-select"
                    placeholder="Select the best time of day"
                    value={IDEAL_TIME_OF_DAY.find(({ id }) => id === idealTimeOfDay.id)}
                    label="Best time of day"
                    withError
                    containerClassName="mt-3"
                    valuePipe={(item) => item}
                  />

                  <FilterDropdown
                    label="Ideal skin type"
                    value={idealSkinTypes.length === IDEAL_SKIN_TYPE.length ? "All" : idealSkinTypes.map(({ text }) => text).join(", ")}
                    showPlaceholder={!idealSkinTypes.length}
                    disabled={notMeantForSkinTypes.length === IDEAL_SKIN_TYPE.length}
                    placeholder="Select ideal skin type (required)"
                    containerClassName="mt-3"
                    listOfCheckboxes={IDEAL_SKIN_TYPE.map(({ id, text }) => {
                      const isSelected = !!idealSkinTypes.find((({ id: selectedId }) => id === selectedId));
                      const isDisabled = notMeantForSkinTypes.some(({ id: selectedId }) => selectedId === id);
                      return {
                        id,
                        text,
                        disabled: isDisabled,
                        value: isSelected,
                        labelClassName: "text-dark",
                        onChange: () => {
                          if (isSelected) {
                            setFieldValue("idealSkinTypes", idealSkinTypes.filter(({ id: selectedId }) => selectedId !== id))
                            return;
                          }
                          setFieldValue("idealSkinTypes", [...idealSkinTypes, { id, text }])
                        }
                      }
                    })}
                    selectAllCheckbox={{
                      text: "Select all",
                      id: "AllIdealTypes",
                      labelClassName: "text-dark",
                      disabled: isAllSkinTypesChosen,
                      value: idealSkinTypes.length === IDEAL_SKIN_TYPE.length,
                      onChange: () => {
                        if (idealSkinTypes.length === IDEAL_SKIN_TYPE.length) {
                          setFieldValue("idealSkinTypes", [])
                          return
                        }
                        setFieldValue("idealSkinTypes", IDEAL_SKIN_TYPE.filter(({ id }) => !notMeantForSkinTypes.some(({ id: selectedId }) => selectedId === id)))
                      }
                    }}
                    clearOption={() => setFieldValue("idealSkinTypes", [])}
                    showClearOption={!!idealSkinTypes.length}
                  />

                  <FilterDropdown
                    label="Not meant for skin type"
                    value={notMeantForSkinTypes.length === IDEAL_SKIN_TYPE.length ? "All" : notMeantForSkinTypes.map(({ text }) => text).join(", ")}
                    showPlaceholder={!notMeantForSkinTypes.length}
                    disabled={idealSkinTypes.length === IDEAL_SKIN_TYPE.length}
                    placeholder="Select not meant for skin type"
                    containerClassName="mt-3"
                    listOfCheckboxes={IDEAL_SKIN_TYPE.map(({ id, text }) => {
                      const isSelected = !!notMeantForSkinTypes.find((({ id: selectedId }) => id === selectedId));
                      const isDisabled = idealSkinTypes.some(({ id: selectedId }) => selectedId === id);
                      return {
                        id,
                        text,
                        disabled: isDisabled,
                        labelClassName: "text-dark",
                        value: isSelected,
                        onChange: () => {
                          if (isSelected) {
                            setFieldValue("notMeantForSkinTypes", notMeantForSkinTypes.filter(({ id: selectedId }) => selectedId !== id))
                            return;
                          }
                          setFieldValue("notMeantForSkinTypes", [...notMeantForSkinTypes, { id, text }])
                        }
                      }
                    })}
                    selectAllCheckbox={{
                      text: "Select all",
                      id: "AllNotMeantTypes",
                      labelClassName: "text-dark",
                      disabled: isAllSkinTypesChosen,
                      value: notMeantForSkinTypes.length === IDEAL_SKIN_TYPE.length,
                      onChange: () => {
                        if (notMeantForSkinTypes.length === IDEAL_SKIN_TYPE.length) {
                          setFieldValue("notMeantForSkinTypes", [])
                          return
                        }
                        setFieldValue("notMeantForSkinTypes", IDEAL_SKIN_TYPE.filter(({ id }) => !idealSkinTypes.some(({ id: selectedId }) => selectedId === id)))
                      }
                    }}
                    clearOption={() => setFieldValue("notMeantForSkinTypes", [])}
                    showClearOption={!!notMeantForSkinTypes.length}
                  />

                  <FilterDropdown
                    label="Contraindications"
                    value={getContradictionsLabel()}
                    showPlaceholder={!contradictions.length}
                    placeholder="Select contraindications"
                    containerClassName="mt-3"
                    listOfCheckboxes={CONTRADICTIONS.map(({ id, text }) => {
                      const isSelected = !!contradictions.find((({ id: selectedId }) => id === selectedId));

                      return {
                        id,
                        text,
                        value: isSelected,
                        labelClassName: "text-dark",
                        onChange: () => {
                          if (isSelected) {
                            setFieldValue("contradictions", contradictions.filter(({ id: selectedId }) => selectedId !== id))
                            return;
                          }
                          setFieldValue("contradictions", [...contradictions, { id, text }])
                        }
                      }
                    })}
                    selectAllCheckbox={{
                      text: "Select all",
                      id: "AllСontradictionTypes",
                      labelClassName: "text-dark",
                      value: contradictions.length === CONTRADICTIONS.length,
                      onChange: () => {
                        if (contradictions.length === CONTRADICTIONS.length) {
                          setFieldValue("contradictions", [])
                          return
                        }
                        setFieldValue("contradictions", CONTRADICTIONS)
                      }
                    }}
                    clearOption={() => setFieldValue("contradictions", [])}
                    showClearOption={!!contradictions.length}
                  />

                </>
              }
            </section>

            <RichTextEditor
              value={values.content ?? ""}
              onChange={(text) => {
                setFieldValue("content", text)
              }}
              name="content"
              label="Description"
              placeholder="Enter a few sentences  about the recommendation, its effects, evidence links etc"
            />

            <Title
              title="Specific impact on biomarker"
              className="mt-5"
            />

            <p className="text-secondary mb-4">Select biomarker(s) to add specific biomarker related information.</p>

            <BiomarkersImpacts
              biomarkersImpacts={values.impacts}
              className="w-50"
              withDelete
              withEdit
              onEdit={(item) => {
                updateEditOption(item);
              }}
              onDelete={(item) => updateDeleteOption(item)}
            />

            <PlusButtonWithTooltip
              id="addBiomarkerImpact"
              onClick={() => updateShowImpactPopup(true)}
              buttonText="Add biomarker"
              containerClassName="mt-3"
              buttonClassName="ps-0"
              showTooltip={false}
            />

            <section className="w-100 d-flex align-items-center justify-content-end mt-4">
              <Button
                color={BUTTON_COLORS.primaryOutline}
                className="me-2"
                onClick={() => {
                  if (isDeepEqual(initialValues, values)) {
                    navigate(isEdit ? replaceUrlParams(RECOMMENDATIONS_LINKS.DETAILS, { id: initialValues.id }) : RECOMMENDATIONS_LINKS.LIST)
                    return;
                  }
                  updateIsOpenCancelPopup(true)
                }}
              >
                Cancel
              </Button>
              <Button
                color={BUTTON_COLORS.primary}
                type="submit"
                loading={isSubmitting}
                className={joinClassNames(isSubmitting && "px-5")}
                disabled={isObjectEmpty(errors) || !values.title || isSubmitting || (isSkinRecommendation && (!idealSkinTypes.length && !isAllSkinTypesChosen))}
              >
                {isEdit ? "Save changes" : "Save recommendation"}
              </Button>
            </section>
          </form>
        )
      }}
    </Formik>
  )
}
