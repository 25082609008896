import React, { useRef } from "react";
import classes from "./MobilePreview.module.scss";
import iphone from "../../../assets/images/iphone.svg"
import iphone_empty from "../../../assets/images/iphone_empty.svg"
import useScrollOnDrag from "react-scroll-ondrag";
import {BOTTOM_NAV_ELEMENTS} from "../../../groups/app/MobileAppCustomisation/constants";
import {ReactComponent as ExploreContentNavIcon} from "../../../groups/app/MobileAppCustomisation/MobileAppPreview/BottomNav/Explore_Nav_Icon.svg";
import {ReactComponent as NutritionProgramNavIcon} from "../../../groups/app/MobileAppCustomisation/MobileAppPreview/BottomNav/Nutrition_Nav_Icon.svg";
import {ReactComponent as MenuNavIcon} from "../../../groups/app/MobileAppCustomisation/MobileAppPreview/BottomNav/Menu_Nav_Icon.svg";
import {ReactComponent as FitnessProgramNavIcon} from "../../../groups/app/MobileAppCustomisation/MobileAppPreview/BottomNav/Fitness_Nav_Icon.svg";
import {ReactComponent as WellnessProgramNavIcon} from "../../../groups/app/MobileAppCustomisation/MobileAppPreview/BottomNav/Wellness_Nav_Icon.svg";
import {ReactComponent as LifestyleDashboardNavIcon} from "../../../groups/app/MobileAppCustomisation/MobileAppPreview/BottomNav/Lifestyle_Nav_Icon.svg";
import {ReactComponent as LabResultsNavIcon} from "../../../groups/app/MobileAppCustomisation/MobileAppPreview/BottomNav/LabResults_Nav_Icon.svg";
import {ReactComponent as MirrorNavIcon} from "../../../groups/app/MobileAppCustomisation/MobileAppPreview/BottomNav/Mirror_Nav_Icon.svg";
import {ReactComponent as BodyNavIcon} from "../../../groups/app/MobileAppCustomisation/MobileAppPreview/BottomNav/Body_Nav_Icon.svg";

const BOTTOM_NAV_ICONS = {
    [BOTTOM_NAV_ELEMENTS.exploreContent]: <ExploreContentNavIcon key={BOTTOM_NAV_ELEMENTS.exploreContent} />,
    [BOTTOM_NAV_ELEMENTS.nutritionProgram]: <NutritionProgramNavIcon key={BOTTOM_NAV_ELEMENTS.nutritionProgram} />,
    [BOTTOM_NAV_ELEMENTS.fitnessProgram]: <FitnessProgramNavIcon key={BOTTOM_NAV_ELEMENTS.fitnessProgram} />,
    [BOTTOM_NAV_ELEMENTS.wellnessProgram]: <WellnessProgramNavIcon key={BOTTOM_NAV_ELEMENTS.nutritionProgram} />,
    [BOTTOM_NAV_ELEMENTS.lifeStyleDashboard]: <LifestyleDashboardNavIcon key={BOTTOM_NAV_ELEMENTS.lifeStyleDashboard} />,
    [BOTTOM_NAV_ELEMENTS.mirrorMirror]: <MirrorNavIcon key={BOTTOM_NAV_ELEMENTS.mirrorMirror} />,
    [BOTTOM_NAV_ELEMENTS.mindAndBody]: <BodyNavIcon key={BOTTOM_NAV_ELEMENTS.mindAndBody} />,
    [BOTTOM_NAV_ELEMENTS.labResults]: <LabResultsNavIcon key={BOTTOM_NAV_ELEMENTS.labResults} />,
    [BOTTOM_NAV_ELEMENTS.menu]: <MenuNavIcon key={BOTTOM_NAV_ELEMENTS.menu} />
}

export default function MobilePreview({ children, isDashboardPage = false, services = [] }) {
  const containerRef = useRef(null);
  const { events } = useScrollOnDrag(containerRef);

  const displayedServices = services.slice(0, 4);

  return (
    <div className={isDashboardPage ? classes.MobilePreviewContainerDashboard : classes.MobilePreviewContainer}
         style={{ background: isDashboardPage ? `url(${iphone_empty}` : `url(${iphone})` }}>
      <div className={classes.MobilePreviewBody} {...events} ref={containerRef}>
        {children}
      </div>
      {isDashboardPage ? <div className={classes.mobileServices}>
        {displayedServices.map((service) => (
          <div className={classes.mobileService} key={service.id}>
            <div className="service-icon">
                {BOTTOM_NAV_ICONS[service.id]}
            </div>
            <div className="service-label">
              {service.name}
            </div>
          </div>
        ))
        }
          <div className={classes.mobileService} key={BOTTOM_NAV_ELEMENTS.menu}>
              <div className="service-icon">
                  {BOTTOM_NAV_ICONS[BOTTOM_NAV_ELEMENTS.menu]}
              </div>
              <div className="service-label">
                  Menu
              </div>
          </div>
      </div> : null}
    </div>
  )
}