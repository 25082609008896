import React from 'react';
import joinClassNames from "../../../../base/helpers/joinClassNames";

const SectionsWithIcon = ({ icon, text }) => (
  <section className="d-flex align-items-center">
    <i className={joinClassNames("font-size-16 me-2", icon)}/>{text}
  </section>
)

export const typeOptions = [
  { id: 1, value: "Increase", label: <SectionsWithIcon text="Increase" icon="text-success bx bx-up-arrow-alt"/> },
  { id: 2, value: "Decrease", label: <SectionsWithIcon text="Decrease" icon="text-danger bx bx-down-arrow-alt"/> },
  { id: 3, value: "Critical", label: <SectionsWithIcon text="Critical" icon="text-yellow bx bx-up-arrow"/> },
  { id: 4, value: "Check", label: <SectionsWithIcon text="Check" icon="text-warning bx bx-right-arrow-alt"/> },
]

export const IDEAL_FREQUENCY = [
  { id: 1, label: "Daily", value: "Daily" },
  { id: 2, label: "Once or twice a week", value: "Once or twice a week" },
  { id: 3, label: "Twice a week", value: "Twice a week" },
  { id: 4, label: "2-3 times a week", value: "2-3 times a week" },
  { id: 5, label: "Every alternate day", value: "Every alternate day" },
  { id: 6, label: "Once a month", value: "Once a month" },
  { id: 7, label: "Twice a month", value: "Twice a month" },
  { id: 8, label: "Once or twice a month", value: "Once or twice a month" },
]

export const IDEAL_TIME_OF_DAY = [
  { id: 1, value: "In the morning", label: "In the morning" },
  { id: 2, value: "At night", label: "At night" },
  { id: 3, value: "During the day", label: "During the day" },
  { id: 4, value: "In the morning and at night", label: "In the morning and at night" },
  { id: 5, value: "Anytime of day", label: "Anytime of day" },
]

export const IDEAL_TIME_OF_DAY_MAP = IDEAL_TIME_OF_DAY.reduce((prevValue, currValue) => ({ ...prevValue, [currValue.id]: currValue}), {});
export const IDEAL_FREQUENCY_MAP = IDEAL_FREQUENCY.reduce((prevValue, currValue) => ({ ...prevValue, [currValue.id]: currValue}), {});

export const IDEAL_SKIN_TYPE = [
  { id: 1, text: "Normal", },
  { id: 2, text: "Dry", },
  { id: 3, text: "Oily", },
  { id: 4, text: "Combination", },
  { id: 5, text: "Sensitive", },
]

export const SKIN_TYPES_MAP = IDEAL_SKIN_TYPE.reduce((prevValue, currValue) => ({ ...prevValue, [currValue.id]: currValue}), {});

export const CONTRADICTIONS = [
  { id: 1, text: "Acne medications", },
  { id: 5, text: "Active skin infection", },
  { id: 13, text: "Botox or fillers", },
  { id: 4, text: "Broken skin", },
  { id: 26, text: "Cancer", },
  { id: 11, text: "Cancer within 2y", },
  { id: 24, text: "Cataracts", },
  { id: 21, text: "Defibrillator", },
  { id: 8, text: "Epilepsy", },
  { id: 18, text: "Haemorrhagic disease", },
  { id: 27, text: "Heart disease", },
  { id: 15, text: "Heart problems", },
  { id: 20, text: "Insensitive to heat", },
  { id: 16, text: "Kidney damage", },
  { id: 17, text: "Liver damage", },
  { id: 22, text: "Lupus", },
  { id: 14, text: "Metal pins or plates", },
  { id: 10, text: "Pacemaker", },
  { id: 28, text: "Photosensitivity disorders", },
  { id: 23, text: "Photosensitizing medication", },
  { id: 6, text: "Pregnancy", },
  { id: 25, text: "Retinal disorders", },
  { id: 2, text: "Rosacea", },
  { id: 12, text: "Thrombosis, Diabetes", },
  { id: 19, text: "Trauma or Bleeding", },
]

export const CONTRADICTIONS_MAP = CONTRADICTIONS.reduce((prevValue, currValue) => ({ ...prevValue, [currValue.id]: currValue}), {});

export const typeValues = typeOptions.reduce((prevValue, currValue) => ({
  ...prevValue,
  [currValue.id]: currValue.label
}), {})

export const SUPPLEMENT_CATEGORY_ID = 3;
export const DOCTOR_CATEGORY_ID = 4;
export const TEST_CATEGORY_ID = 5;
export const SKIN_RECOMMENDATION_ID = 7;

export const SKIN_RECOMMENDATIONS_IDS = [SKIN_RECOMMENDATION_ID, 8, 9];

export const categoryOptions = [
  { id: 1, value: "Diet", label: "Diet" },
  { id: 2, value: "Lifestyle", label: "Lifestyle" },
  { id: SUPPLEMENT_CATEGORY_ID, value: "Supplements", label: "Supplements" },
  { id: DOCTOR_CATEGORY_ID, value: "Doctor", label: "Doctor" },
  { id: TEST_CATEGORY_ID, value: "Test", label: "Test" },
  { id: 6, value: "Information", label: "Information" },
  { id: SKIN_RECOMMENDATION_ID, value: "Skin product", label: "Skin product" },
  { id: 8, value: "DIY", label: "DIY" },
  { id: 9, value: "At-home device", label: "At-home device" },
]

export const activityOptions = [
  { id: 1, value: "Active", label: "Active", valueForBackend: false, },
  { id: 2, value: "Archived", label: "Archived", valueForBackend: true },
]

export const HIERARCHY_OPTIONS = {
  PARENT: 1,
  CHILD: 2,
}

export const HIERARCHY_OPTIONS_LABELS = {
  [HIERARCHY_OPTIONS.PARENT]: "Parent",
  [HIERARCHY_OPTIONS.CHILD]: "Child",
}

export const selectAllRecommendations = {
  text: "Select all", id: "AllRecommendations", className: ""
}

export const generateMapFromArray = (array, key, value) => {
  return array.reduce((prevValue, currValue) => ({
    ...prevValue,
    [currValue[key]]: currValue[value],
  }), {});
}

export const activityValues = generateMapFromArray(activityOptions, "id", "valueForBackend");

export const tableCategories = generateMapFromArray(categoryOptions, "id", "label");

export const LOW_RANGE = "low_range";
export const HIGH_RANGE = "high_range";

export const IMPACT_TYPES = [
  { label: "Low range", value: LOW_RANGE },
  { label: "High range", value: HIGH_RANGE },
];

export const SKIN_IMPACT_TAB = [
  { label: "Poor / Can do Better / Scope to improve", value: LOW_RANGE },
]

const STRENGTH_OF_EVIDENCE_VALUES = [null, "Uncertain", "Weak", "Strong"];

const QUALITY_OF_EVIDENCE_VALUES = [null, "Very low", "Low", "Moderate", "High", "Very high"]

const qualityOfEvidence = {
  defaultValue: 3,
  min: 1,
  max: 5,
  label: "Quality of evidence",
  labelClassName: "",
  containerClassName: "mt-2",
  displayedValue: QUALITY_OF_EVIDENCE_VALUES,
}

const strengthOfEvidence = {
  defaultValue: 2,
  min: 1,
  max: 3,
  label: "Strength of evidence",
  labelClassName: "",
  containerClassName: "mt-2",
  displayedValue: STRENGTH_OF_EVIDENCE_VALUES
}

const impactLevel = {
  defaultValue: 0,
  min: -10,
  max: 10,
  label: "Set impact level",
  labelClassName: "",
  containerClassName: "mt-2",
}

const viewImpactLevel = {
  ...impactLevel,
  label: "Impact level",
}

export const IMPACT_TYPE_FIELDS = {
  [LOW_RANGE]: {
    editor: "descriptionLow",
    studyLinks: "lowStudyLinks",
    impacts: [
      {
        fieldKey: "impactLevelLow",
        ...impactLevel
      },
      {
        fieldKey: "qualityOfEvidenceLow",
        ...qualityOfEvidence,
      },
      {
        fieldKey: "strengthOfEvidenceLow",
        ...strengthOfEvidence,
      },
    ]
  },
  [HIGH_RANGE]: {
    editor: "descriptionHigh",
    studyLinks: "highStudyLinks",
    impacts: [
      {
        fieldKey: "impactLevelHigh",
        ...impactLevel
      },
      {
        fieldKey: "qualityOfEvidenceHigh",
        ...qualityOfEvidence,
      },
      {
        fieldKey: "strengthOfEvidenceHigh",
        ...strengthOfEvidence,
      },
    ]
  }
}

export const IMPACT_TYPE_FIELDS_VIEW = {
  [LOW_RANGE]: {
    editor: "descriptionLow",
    studyLinks: "lowStudyLinks",
    impacts: [
      {
        fieldKey: "impactLevelLow",
        ...viewImpactLevel
      },
      {
        fieldKey: "qualityOfEvidenceLow",
        ...qualityOfEvidence,
      },
      {
        fieldKey: "strengthOfEvidenceLow",
        ...strengthOfEvidence,
      },
    ]
  },
  [HIGH_RANGE]: {
    editor: "descriptionHigh",
    studyLinks: "highStudyLinks",
    impacts: [
      {
        fieldKey: "impactLevelHigh",
        ...viewImpactLevel
      },
      {
        fieldKey: "qualityOfEvidenceHigh",
        ...qualityOfEvidence,
      },
      {
        fieldKey: "strengthOfEvidenceHigh",
        ...strengthOfEvidence,
      },
    ]
  }
}

export const MAX_BENEFIT_ITEMS = 3;
export const HEALF_ORG_ID = 2;
