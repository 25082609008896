import React, {useEffect, useMemo, useState} from "react";
import {useService} from "../../../../base/hooks/useService";
import RecommendationsService from "../../../../services/RecommendationsService";
import {useLoading} from "../../../../base/hooks/useLoading";
import FilterDropdown from "../../Biomarkers/CreateEdit/components/FilterDropdown";
import {keyBy} from "lodash";

export default function BenefitDropdown({
                                            label,
                                            onChange,
                                            value,
                                            error
                                        }) {
    /**
     * @type {RecommendationsService}
     */
    const recommendationsService = useService(RecommendationsService);

    const selectedBenefits = useMemo(() => value ?? [], [value]);

    const [isLoading, { registerPromise }] = useLoading(true);
    const [benefits, updateBenefits] = useState([]);
    const [selectedParentBenefits, updateSelectedParentBenefits] = useState(selectedBenefits);

    const selectedParentBenefitsById = useMemo(() => keyBy(selectedParentBenefits, 'id'), [selectedParentBenefits]);

    const getBenefits = () =>
        registerPromise(
            recommendationsService.getRecommendationBenefits()
                .then(({ data }) => {
                    updateBenefits(data);
            })
        );

    useEffect(() => {
        getBenefits()
    }, []);

    const handleCheckboxChange = (value, currentState, updateState) => {
        if(currentState.find(({ id }) => value.id === id)) {
            updateState(prevState => prevState.filter(({ id }) => id !== value.id))
            return
        }
        updateState(prevState => [...prevState, value]);
    }

    useEffect(() => {
        onChange(selectedParentBenefits.map(({ id }) => id));
    }, [selectedParentBenefits]);

    return(
        <FilterDropdown
            label={label}
            placeholder="Select benefit"
            value={selectedParentBenefits.map(({ name }) => name).join(", ")}
            showPlaceholder={selectedParentBenefits.length === 0}
            showSelectAllCheckboxes={false}
            selectAllCheckbox={[]}
            listOfCheckboxes={benefits.map((benefit) => {
                return {
                    id: benefit.id,
                    text: benefit.name,
                    value: !!selectedParentBenefitsById[benefit.id],
                    onChange: () => handleCheckboxChange(benefit, selectedParentBenefits, updateSelectedParentBenefits)
                }
            })}
            clearOption={() => updateSelectedParentBenefits([])}
            error={error}
        />
    )
}