import React from 'react';
import DailyCaloriesWidget from "./DailyCalories/DailyCaloriesWidget";
import WaterIntakeWidget from "./WaterIntake/WaterIntakeWidget";
import DeviceDataWidget from "./DeviceData/DeviceDataWidget";
import MirrorMirrorMindWidget from "./MirrorMirrorMind/MirrorMirrorMindWidget";
import PropTypes from "prop-types";
import MirrorMirrorBodyWidget from "./MirrorMirrorBody/MirrorMirrorBodyWidget";
import FastingWidget from "./Fasting/FastingWidget";
import MoodWidget from "./Mood/MoodWidget";
import TopNavBar from "./TopNavBar/TopNavBar";
import classes from "./MobileAppPreview.module.scss";

export default function NutritionalDashboard({primaryColor = "", secondaryColor = ""}) {
  return (
      <div className="position-relative">
        <div className={classes.dynamicIsland} />
        <TopNavBar />
        <DailyCaloriesWidget primaryColor={primaryColor} secondaryColor={secondaryColor} />
        <WaterIntakeWidget primaryColor={primaryColor} />
        <DeviceDataWidget primaryColor={primaryColor} secondaryColor={secondaryColor} />
        <MirrorMirrorMindWidget primaryColor={primaryColor} />
        <MirrorMirrorBodyWidget primaryColor={primaryColor} />
        <FastingWidget primaryColor={primaryColor}  />
        <MoodWidget primaryColor={primaryColor} secondaryColor={secondaryColor} />
      </div>
  )
}

NutritionalDashboard.propTypes = {
 primaryColor: PropTypes.string,
 secondaryColor: PropTypes.string,
}