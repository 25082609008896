import React, { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem, Spinner
} from "reactstrap";
import _ from "lodash";
import { useLoading } from "../hooks/useLoading";
import joinClassNames from "../helpers/joinClassNames";

const ACCORDION_ITEM_ID = "1";

function ActionItems({ value, setValue, index: levelIndex, removeLevel }) {

  const actionPlans = _.chain(value)
    .get('actionPlans', [])
    .sortBy('step')
    .value();

  const isLastElement = (index) => index === actionPlans.length - 1;

  return (
    <>
      <div className="level-title">General</div>
      <div className="d-flex mb-3 gap-3 fw-bold px-3 pt-3">
        <div className="d-flex action-number justify-content-center">#</div>
        <div className="action-type">Content type</div>
        <div>Name</div>
      </div>
      {actionPlans.map((item, index) =>
        <div
          key={index}
          className={joinClassNames('d-flex gap-3 px-3', isLastElement(index) ? null : 'mb-3')}
        >
          <div className="d-flex action-number justify-content-center">{item?.step}</div>
          <div className="action-type">
            {item?.contentType}
          </div>

          <div className="flex-grow-1">
            {item?.name}
          </div>
        </div>
      )}
    </>)

}

function Level({ value, index }) {
  const [open, setOpen] = useState([ACCORDION_ITEM_ID]);

  const toggle = () => {
    setOpen(open.length ? [] : [ACCORDION_ITEM_ID]);
  };

  return (
    <Accordion flush open={open} toggle={toggle} className="mt-4">
      <AccordionItem
        id={ACCORDION_ITEM_ID}
      >
        <AccordionHeader
          className="white-bg-accordion-header border-0"
          targetId={ACCORDION_ITEM_ID}>
          <span className="accordion-description font-weight-semibold">Level {value?.level}</span>
        </AccordionHeader>
        <AccordionBody accordionId={ACCORDION_ITEM_ID} className="bg-body">
          <ActionItems value={value} index={index}/>
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  )
}

export function PlanOfActionView({ value }) {
  return <div className="plan-of-action-accordion mt-4">
    {value.map((item, index) => (
      <Level key={index} value={item} index={index}/>
    ))}
  </div>
}