import Http from "./Http";

class RecommendationsService extends Http {
  static $displayName = "RecommendationsService";

  async getRecommendations(params) {
    return await this.get("/biomarkers/recommendations", { params })
  }

  async createRecommendation(data) {
    return await this.post("/biomarkers/recommendations", data);
  }

  async editRecommendation(id, data) {
    return await this.put(`/biomarkers/recommendations/${id}`, data);
  }

  async archiveRecommendation(id, data) {
    return await this.patch(`/biomarkers/recommendations/${id}`, data);
  }

  async deleteRecommendation(id) {
    return await this.delete(`/biomarkers/recommendations/${id}`);
  }

  async getRecommendation(id) {
    return await this.get(`/biomarkers/recommendations/${id}`);
  }

  async getRecommendationTags(params){
    return await this.get(`/biomarkers/recommendations/tags`, { params })
  }

  async getRecommendationBenefits(){
    return await this.get(`/biomarkers/recommendations/benefits`)
  }
}

export default RecommendationsService;