import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row } from "reactstrap";
import Breadcrumb from "../../../base/components/Common/Breadcrumb2";
import { CUSTOMER_MANAGEMENT_LINKS } from "./config";
import { useLoading } from "../../../base/hooks/useLoading";
import { CSV_FILE_MASK, DEFAULT_DEBOUNCE_DELAY, USER_ROLES } from "../../../base/constants/shared";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useService } from "../../../base/hooks/useService";
import Button from "../../../base/components/Button";
import PageSpinner from "../../../base/components/PageSpinner";
import UserResultsService from "../../../services/UserResults";
import UsersService from "../../../services/UsersService";
import { BUTTON_COLORS } from "../../../base/components/Button/appearance";
import ValueWithLabel from "./Details/ValueWIthLabel";
import { GENDERS } from "../../../base/constants/shared";
import UploadFileModal from './UsersList/UploadFileModal';
import { replaceUrlParams } from '../../../base/helpers/url';
import FileUpload from "../CustomerResults/ResultsList/FileUpload";
import TagsPopup from './Details/TagsPopup';
import ToasterService from "../../../services/ToastService";
import classNames from "classnames";
import LabResultsList from "./Details/LabResultsList";
import AttributesList from "./Details/AttributesList";
import UserSegmentsList from "./Details/UserSegmentsList";
import EditTagPopup from "./Details/EditTagPopup";
import AuthService from "../../../services/AuthService";
import { AUTH_GROUP_LINKS } from "../../auth/config";
import { KEY_USER } from "../../../base/constants/storage";
import StorageService from "../../../services/StorageService";

const breadcrumbs = {
  title: "Customer’s detailed page",
  breadcrumbItems: [
    { title: "Customer Management", link: CUSTOMER_MANAGEMENT_LINKS.LIST },
    { title: "Customer’s detailed page" }
  ]
}

const TABS = {
  results: "Labs Test Results",
  segments: "User Segments",
  attributes: "Attributes"
}

export default function Details() {
  /**
   * @type {UsersService}
   */
  const usersService = useService(UsersService);
  /**
   * @type {UserResultsService}
   */
  const userResultsService = useService(UserResultsService);
  /**
   * @type {ToasterService}
   */
  const toastService = useService(ToasterService);
  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);
  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);

  const currentAdminUser = storage.get(KEY_USER, { firstName: "", lastName: "" });
  const { search: locationSearch } = useLocation();
  const [user, setUser] = useState({});

  const [isCsvPopupOpen, setIsCsvPopupOpen] = useState(false);
  const [isOpenPDFFilesPopup, updateIsOpenPDFFilesPopup] = useState(false);

  const [isOpenTagPopup, setIsOpenTagPopup] = useState(false);
  const [isOpenEditTagPopup, setIsOpenEditTagPopup] = useState(false);
  const [selectedTagId, setSelectedTagId] = useState(null);

  const [lastResultDates, setLastResultDates] = useState([]);

  const [isLoading, { registerPromise }] = useLoading(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [isUploadDNAFileModalOpen, setIsUploadDNAFileModalOpen] = useState(false);
  
  const [activeTab, updateActiveTab] = useState(TABS.results);

  const isUserResult = useCallback((r) => {
    return r.blood !== undefined;
  }, [])

  const isUserSkinResult = useCallback((r) => {
    return isUserResult(r) === false;
  }, [isUserResult])

  const fetchUser = useCallback(
    () => {
      usersService.getUserById(id).then((data) => {
        setUser(data)
      })
    },
    [id],
  )

  const fetchDates = useCallback(
    () => {
      return usersService
        .getLabsDates(id)
        .then((data) => {
          setLastResultDates(data);
        })
    },
    [id],
  )
  
  useEffect(() => {
    navigate(window.location.pathname, { replace: true });
  }, [activeTab]);

  useEffect(() => {
    registerPromise(Promise.all([
      fetchUser(),
      fetchDates(),
    ]))
  }, [id]);
  
  let table;
  switch (activeTab) {
    case TABS.attributes:
      table = (
        <AttributesList/>
      );
      break;
    case TABS.segments:
      table = (
        <UserSegmentsList/>
      );
      break;
    default:
      table = (
        <LabResultsList setIsCsvPopupOpen={setIsCsvPopupOpen} isOpenPDFFilesPopup={isOpenPDFFilesPopup} updateIsOpenPDFFilesPopup={updateIsOpenPDFFilesPopup} user={user} />
      );
      break;
  }

  return (
    <>
      <Breadcrumb {...breadcrumbs} />
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              {isLoading ?
                <PageSpinner />
                :
                <>
                  <section className="d-flex align-items-center justify-content-between mb-4">
                    <label className="font-weight-semibold font-size-15">{user.firstName} {user.lastName}</label>
                    <div className='d-flex'>
                      <Button
                        color={BUTTON_COLORS.primaryOutline}
                        onClick={() => {
                          registerPromise(authService.generateSessionCode(user.id))
                            .then((data) => {
                              window.open(CUSTOMER_MANAGEMENT_LINKS.VIEW_DASHBOARD.replace(':id', user.id).replace(':sessionCode', data.code),'_blank');
                            });
                        }}
                        className="d-flex align-items-center me-2"
                      >
                        View Customer Dashboard
                      </Button>
                      <Button
                        color={BUTTON_COLORS.primaryOutline}
                        onClick={() => setIsOpenTagPopup(true)}
                        className="d-flex align-items-center me-2"
                      >
                        Latest assessment data
                      </Button>
                      {currentAdminUser.role !== USER_ROLES.practitioner ? (
                        <>
                          <Button
                            color={BUTTON_COLORS.primary}
                            onClick={() => setIsUploadDNAFileModalOpen((prevState) => !prevState)}
                            className="d-flex align-items-center me-2"
                          >
                            <i className="bx bx-cloud-upload me-2"/>
                            Upload DNA file
                          </Button>
                          <Button
                            color={BUTTON_COLORS.primary}
                            onClick={() => setIsCsvPopupOpen({ userId: id })}
                            className="d-flex align-items-center me-2"
                          >
                            <i className="bx bx-cloud-upload me-2"/>
                            Upload Food Intolerance file
                          </Button>
                          <Button
                            color={BUTTON_COLORS.primary}
                            onClick={() => navigate(replaceUrlParams(CUSTOMER_MANAGEMENT_LINKS.UPLOAD_MANUALLY, { id }))}
                            className="d-flex align-items-center me-2"
                          >
                            <i className="bx bx-cloud-upload me-2"/>
                            Upload Manually
                          </Button>
                        </>
                      ) : null}
                    </div>
                  </section>

                  <section className='d-flex flex-wrap flex-row mb-4 p-relative'>
                    <ValueWithLabel
                      value={user.email}
                      label="Email"
                      className={"w-1-5 mb-3 px-2"}
                    />

                    <ValueWithLabel
                      value={GENDERS[user?.additionalField?.sex]}
                      label="Gender"
                      className={"w-1-5 px-2"}
                    />

                    <ValueWithLabel
                      value={user.id}
                      label="ID"
                      className={"w-1-5 px-2"}
                    />

                    <ValueWithLabel
                      value={user?.additionalField?.dateOfBirth?.split('-').reverse().join('/')}
                      label="Birth date"
                      className={"w-1-5 px-2"}
                    />

                    <ValueWithLabel
                      value={user?.createdAt?.split('T')[0].split('-').reverse().join('/')}
                      label="Join date"
                      className={"w-1-5 px-2"}
                    />

                    <ValueWithLabel
                      value={lastResultDates.filter(isUserResult)?.[0]?.blood?.split('-').reverse().join('/')}
                      label="Health assessment date"
                      className={"w-1-5 px-2"}
                    />

                    <ValueWithLabel
                      value={lastResultDates.filter(isUserSkinResult)?.[0]?.skin?.split('T')[0].split('-').reverse().join('/')}
                      label="Skin assessment date"
                      className={"w-1-5 px-2"}
                    />

                    <ValueWithLabel
                      value={user?.pdfFilesCount || 0}
                      label="Uploaded Files"
                      className={"w-1-5 px-2"}
                    />

                    <section className={"d-flex w-1-5 px-2"}>
                      <Button
                        color={BUTTON_COLORS.primaryOutline}
                        onClick={() => updateIsOpenPDFFilesPopup(true)}
                        className="d-flex align-items-center me-2"
                      >
                        View Files
                      </Button>
                    </section>

                    {user?.practitioner ? (
                      <ValueWithLabel
                        value={`${user?.practitioner?.firstName} ${user?.practitioner?.lastName}`}
                        label="Practitioner"
                        className={"w-1-5"}
                      />
                    ) : null}
                  </section>
                  <section className="customer-results-details-tabs">
                    <Nav tabs className="nav-tabs-custom mt-2 mb-3">
                      {Object.values(TABS).map((label, index) => (
                        <NavItem key={`navItem${index}`}>
                          <NavLink
                            className={classNames(
                              { active: activeTab === label },
                              "cursor-pointer py-1 mx-1"
                            )}
                            onClick={() => updateActiveTab(label)}
                          >
                            <span>{label}</span>
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                    {table}
                  </section>
                </>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>

      {isUploadDNAFileModalOpen && (
        <UploadFileModal
          isOpen={isUploadDNAFileModalOpen}
          updateIsOpen={setIsUploadDNAFileModalOpen}
          title="Upload DNA file"
          description="Drag & drop your DNA file or click to upload."
          submitBtnText="Confirm"
          fileMask={CSV_FILE_MASK}
        />
      )}

      {!!isCsvPopupOpen &&
        <FileUpload
          userId={isCsvPopupOpen.userId}
          testObjectId={isCsvPopupOpen.id}
          isOpen={isCsvPopupOpen !== false}
          updateIsOpen={setIsCsvPopupOpen}
          title="Upload Food Intolerance result"
          description="Drag & drop your Food Intolerance file or click to upload."
          submitBtnText="Confirm"
          fileMask={CSV_FILE_MASK}
          afterUpload={fetchUser}
        />
      }

      <TagsPopup isOpen={isOpenTagPopup} updateIsOpen={(s) => setIsOpenTagPopup(s)} updateEditTag={(id) => {
        setSelectedTagId(id);
        setIsOpenEditTagPopup(true);
        setIsOpenTagPopup(false);
      }}/>
      <EditTagPopup isOpen={isOpenEditTagPopup} selectedTagId={selectedTagId} onClose={() => {
        setIsOpenEditTagPopup(false);
        setSelectedTagId(null);
      }} />
    </>
  )
}
