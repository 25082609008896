import { Formik } from "formik";
import * as _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import BaseLayoutWithCard from "../../../../base/components/BaseLayoutWithCard";
import Button from "../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";
import { DropZoneCard } from "../../../../base/components/Dropzone";
import useUploadImages from "../../../../base/components/Dropzone/useUploadImages";
import FormikColorPicker from "../../../../base/components/FormikColorPicker";
import Icon from "../../../../base/components/Icon";
import MobilePreview from "../../../../base/components/MobilePreview/MobilePreview";
import {
  ALLOWED_LOGO_FILE_EXTENSION_EXTENDED,
  ASSETS_FILE_TYPE,
  BYTES_IN_KILOBYTE,
  ERROR_ALLOWED_LOGO_FILE_TYPE_EXTENDED_MESSAGE,
  ERROR_ALLOWED_LOGO_FILE_TYPE_MESSAGE,
  ERROR_LOGO_FILE_SIZE_MESSAGE,
  KILOBYTES_IN_MEGABYTE,
  LOGO_FILE_MASK_EXTENDED,
  MAX_LOGO_FILE_SIZE
} from "../../../../base/constants/shared";
import joinClassNames from "../../../../base/helpers/joinClassNames";
import { useService } from "../../../../base/hooks/useService";
import ConfigService from "../../../../services/ConfigService";
import ToasterService from "../../../../services/ToastService";
import { validateEmptyFile, validateFileSize, validateFileType } from "../../../../validation/fileUploadAndProcessing";
import { checkOnDefined } from "../../../app/Biomarkers/CreateEdit/fromValidation";
import Title from "../../Biomarkers/Details/Title";
import { SortableList } from "../components/SortableList";
import { initialValues as initialValuesStatic, validationSchema } from "./form";
import NutritionalDashboard from "../MobileAppPreview/NutritionalDashboard.template";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export function MobileAppCustomisationForm() {
  const breadcrumbs = {
    title: "Mobile App Customisation", breadcrumbItems: []
  };

  /**
   * @type {ConfigService}
   */
  const configService = useService(ConfigService);
  /**
   * @type {ToasterService}
   */
  const toastService = useService(ToasterService);

  const [initialValues, setInitialValues] = useState(initialValuesStatic);
  const [mobileServices, setMobileServices] = useState([]);
  const [isLoading, updateIsLoading] = useState(true);
  const [isSubmitting, updateIsSubmitting] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [apiSettings, setApiSettings] = useState({});
  const [formValues, setFormValues] = useState({});
  const [isUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const uploadImage = useUploadImages();

  useEffect(() => {
    Promise.all([
      configService.fetchThemeSettings(),
      configService.fetchMobileServices()
    ]).then(([themeSettingsRaw, mobileServices]) => {
      const themeFields = ["id", "propertyName", "fileId", "value", "file", "page", "themeType"];
      const themeSettings = _.chain(themeSettingsRaw)
        .get("data")
        .map(item => _.pick(item, themeFields))
        .keyBy("propertyName")
        .value();

      setApiSettings(themeSettings);
      setSelectedFile({
        ...themeSettings.organisationLogoMobile
      });
      updateIsLoading(false);

      setInitialValues((prev) => ({
        ...prev,
        organisationLogoMobile: themeSettings.organisationLogoMobile.value,
        primaryColorMobile: themeSettings.primaryColorMobile.value,
        secondaryColorMobile: themeSettings.secondaryColorMobile.value,
      }));
      setMobileServices(mobileServices.data);
    })
  }, [configService]);

  const activeMobileServices = useMemo(
      () =>
          mobileServices
              .filter(({ isEnabled }) => isEnabled)
              .map(({ name, ...service }) => ({
                ...service,
                name: name.split(" ")[0],
              })),
      [mobileServices],
  )

  const handleAcceptedFile = useCallback(async (file) => {
    setErrorMessage("");

    try {
      validateFileType(file, ALLOWED_LOGO_FILE_EXTENSION_EXTENDED, ERROR_ALLOWED_LOGO_FILE_TYPE_EXTENDED_MESSAGE);
      validateFileSize(file, MAX_LOGO_FILE_SIZE, ERROR_LOGO_FILE_SIZE_MESSAGE);
      validateEmptyFile(file);
    } catch ({ message }) {
      toastService.error(message)
      setErrorMessage(message);
      return;
    }

    setSelectedFile((prev) => {
      return {
        ...prev,
        value: URL.createObjectURL(file),
        fileData: file,
        file: {
          name: file.name,
          bytes: file.size
        }
      }
    });

  }, [setErrorMessage]);

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === ERROR_ALLOWED_LOGO_FILE_TYPE_EXTENDED_MESSAGE) {
            toastService.error(ERROR_ALLOWED_LOGO_FILE_TYPE_MESSAGE);
          }
        });
      });

      if (!acceptedFiles.length) return;

      acceptedFiles.forEach((file) => handleAcceptedFile(file));

    },
    [handleAcceptedFile]
  );

  const deleteLogo = () => {
    setSelectedFile(null);
  }

  const submitSettings = async (values) => {
    updateIsSubmitting(true);
    try {
      const settingsToSubmit = _.pick(apiSettings, _.keys(values));
      _.map(settingsToSubmit, (setting) => {
        setting.value = values[setting.propertyName];
        return setting;
      });

      if (!selectedFile.id) {
        const uploadedFile = await uploadImage(selectedFile.fileData, ASSETS_FILE_TYPE); // uploadedFile.file.id
        settingsToSubmit.organisationLogoMobile = {
          ...settingsToSubmit.organisationLogoMobile,
          fileId: uploadedFile.file.id
        };
        setSelectedFile((prev) => ({
          ...prev,
          id: uploadedFile.file.id
        }));
      }

      settingsToSubmit.organisationLogoMobile = _.omit(settingsToSubmit.organisationLogoMobile, ['value', 'file']);
      const preparedMobileServices = _.map(mobileServices, (mobileService) => _.pick(mobileService, ["id", "isEnabled", "order"]));
      await Promise.all([
        configService.updateThemeSettings(_.values(settingsToSubmit)),
        configService.updateMobileServices(preparedMobileServices)
      ]);
      toastService.success("Settings was successfully uploaded");
    } catch (err) {
      console.error(err);
      toastService.error(err)
      setErrorMessage(err);
    } finally {
      updateIsSubmitting(false);
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setMobileServices((prev) => (
      reorder(prev, oldIndex, newIndex).map(
        ({ order, ...rest }, index) => ({
          ...rest,
          order: checkOnDefined(order) ? index + 1 : undefined
        })
      )
    ));
  };

  const toggleService = (id) => {
    setMobileServices((prev) => (
      prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            isEnabled: !item.isEnabled
          }
        }
        return item;
      })
    ));
  }

  return <BaseLayoutWithCard breadcrumbs={breadcrumbs}>
    <div className="w-100">
      <div className="d-flex pt-3 justify-content-end">

      </div>
      <div className="d-flex gap-5">
        <section className="w-50 pe-4 border-end border-1" style={{ minWidth: "50%" }}>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnBlur
            onSubmit={submitSettings}
            enableReinitialize
          >
            {({ errors, handleSubmit, values }) => {
              setFormValues(values)
              return(
                  <form className={joinClassNames("form-horizontal", isSubmitting && "pointer-events-none")}
                        onSubmit={handleSubmit}>
                    <label htmlFor="">Logo</label>
                    {isLoading &&
                    <Skeleton height={93} />
                    }
                    {!selectedFile && !isLoading &&
                    <DropZoneCard
                        onDrop={onDrop}
                        errorMessage={errorMessage}
                        isDropContainer={true}
                        className={""}
                        fileMask={LOGO_FILE_MASK_EXTENDED}
                    >
                      <section className="upload-container--section">
                        <p className="upload-container--section__cloud-upload-desc">Upload logo</p>
                        <p className="upload-container--section__desc mb-0">Drop a PNG, JPG or JPEG file.</p>
                        <p
                            className="upload-container--section__desc">Max {MAX_LOGO_FILE_SIZE / BYTES_IN_KILOBYTE / KILOBYTES_IN_MEGABYTE} Mb</p>
                      </section>
                    </DropZoneCard>
                    }
                    {selectedFile && !isLoading && !isUploading &&
                    <div>
                      <div
                          className="d-flex align-items-center justify-content-between p-4 selected-file-container formik-file-input">
                        <div className="d-flex align-items-center">
                          <img src={selectedFile.value} alt="Logo" className="formik-file-input-logo" />
                          <div className="d-flex flex-column justify-content-between px-4">
                          <span
                              className="selected-file-container__selected-file-name mb-1">{selectedFile.file.name}</span>
                            <span className="font-semibold">
                            {(selectedFile.file.bytes / BYTES_IN_KILOBYTE).toFixed(2)} KB
                          </span>
                          </div>
                        </div>
                        <Icon icon="trashIcon" className="cursor-pointer ms-3" onClick={deleteLogo} />
                      </div>
                    </div>
                    }

                    <FormikColorPicker
                        label="Primary color"
                        description="Primary color of buttons and important elements. This color will be displayed in all related elements on the mobile app."
                        placeholder=""
                        name="primaryColorMobile"
                        maxLength={255}
                        containerClassName="mt-3 mb-4"
                        isLoading={isLoading}
                    />
                    <FormikColorPicker
                        label="Secondary color"
                        placeholder=""
                        name="secondaryColorMobile"
                        maxLength={255}
                        containerClassName="mt-3 mb-4"
                        isLoading={isLoading}
                    />

                    <label htmlFor="">Features</label>

                    {isLoading ?
                        <Skeleton height={355} />
                        :
                        <SortableList
                            items={mobileServices}
                            helperClass="mobile-services-item"
                            onSortEnd={onSortEnd}
                            onChange={toggleService}
                            axis="xy"
                            lockOffset="100%"
                            lockToContainerEdges
                            disableAutoscroll
                        />
                    }

                    <div className="d-flex justify-content-end mt-5 gap-3">
                      <Button
                          color={BUTTON_COLORS.primary}
                          type="submit"
                          disabled={!!Object.keys(errors).length || isSubmitting}
                      >
                        Save changes
                      </Button>
                    </div>
                  </form>
              )
            }}
          </Formik>

        </section>

        <section className="w-30 ps-4">
          <Title
            title="Preview"
          />

          <div className="w-100 d-flex align-items-center justify-content-center">
            <MobilePreview isDashboardPage services={activeMobileServices}>
              <NutritionalDashboard primaryColor={formValues.primaryColorMobile} secondaryColor={formValues.secondaryColorMobile} />
            </MobilePreview>
          </div>
        </section>
      </div>
    </div>

  </BaseLayoutWithCard>;
}